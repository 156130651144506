import { type TabsPresenter } from '$ui/tabs'
import { bva } from '$ui/utils'

export const standard = {
  content: bva('w-full caption-bottom text-sm', { }),
  list: bva('[&_tr]:border-b', { }),
  trigger: bva('[&_tr:last-child]:border-0', { }),
} satisfies TabsPresenter

declare module '$ui/tabs' {
  interface TabsPresenters {
  }
}
