import createCacheRequest from '$$/lib/createCacheRequest'
import type { Account } from '$$/models'
import { useCookies } from '@vueuse/integrations/useCookies'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useSessionStore = defineStore('Session', () => {
  const cookies = useCookies(['session_email'])
  const email = computed(() => cookies.get('session_email'))

  function update(account: Account) {
    cookies.set('session_email', account.email)
  }

  function clear() {
    cookies.remove('session_email')
    cleanup()
  }

  const { request, cleanup, cache } = createCacheRequest()
  const isSignedIn = computed(() => email.value !== null)
  return { email, update, clear, isSignedIn, request, cache }
})
