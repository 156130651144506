<template>
  <PresetsProvider>
    <div v-if="error">
      {{ error }}
    </div>
    <template v-else>
      <router-view />
    </template>
  </PresetsProvider>
</template>

<script setup lang="ts">
import { onErrorCaptured, ref } from 'vue'
import { useRouter } from 'vue-router'
import { UnauthorizedError } from './lib/errors'
import { useSessionStore } from './stores/session'
import PresetsProvider  from '$presets/PresetsProvider.vue'
import { usePageStore } from './stores/page'

const router = useRouter()
const error = ref(null as null | Error)
const session = useSessionStore()
const page = usePageStore()

onErrorCaptured((err) => {
  if (err instanceof UnauthorizedError) {
    session.clear()
    page.sweepGoto({ name: 'sign_in' })
    return false
  }

  error.value = err
  console.error(err)
  return false
})

router.afterEach((from, to) => {
  error.value = null
})
</script>
