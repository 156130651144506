import { setupLayouts } from 'virtual:generated-layouts'
import { type App } from "vue"
import { createMemoryHistory, createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { routes } from 'vue-router/auto-routes'

const isSSR = import.meta.env.SSR

function traverseRoutes(routes: RouteRecordRaw[], callback: (route: RouteRecordRaw) => void) {
  for (const route of routes) {
    callback(route)

    if (route.children && route.children.length > 0) {
      traverseRoutes(route.children, callback)
    }
  }
}

traverseRoutes(routes, (route) => {
  if (!route.component) {
    return
  }

  const defaultLayout = route.meta?.namespace ?? 'default'
  route.meta = { layout: defaultLayout, ...route.meta }
})

const router = createRouter({
  history: isSSR ? createMemoryHistory() : createWebHistory(""),
  routes: setupLayouts(routes),
})

export default function(app: App) {
  app.use(router)
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $router: typeof router
  }
}
