import { type DropdownMenuPresenter } from '$ui/dropdown-menu'
import { bva } from '$ui/utils'

export const standard = {
  content: bva(`
    z-50 min-w-32 rounded-md border bg-popover-background p-1 text-popover-foreground shadow-md
    overflow-hidden overflow-y-auto max-h-[var(--radix-dropdown-menu-content-available-height)]
    data-[state=open]:animate-in data-[state=closed]:animate-out
    data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0
    data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95
    data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2
  `, {}),
  item: bva(`
    relative flex w-full select-none items-center rounded-sm px-2 py-1.5 text-sm outline-hidden transition-colors
    cursor-pointer
    focus:bg-accent focus:text-accent-foreground
    data-disabled:pointer-events-none data-disabled:opacity-50
    data-inset:pl-8
  `, {}),
  separator: bva(`-mx-1 my-1 h-px bg-border`, {}),
  trigger: bva(`outline-hidden cursor-pointer`, {}),
  sub: bva('', {}),
  subContent: bva('', {}),
  subTrigger: bva('', {}),
  label: bva('', {}),
} satisfies DropdownMenuPresenter

declare module '$ui/dropdown-menu' {
  interface DropdownMenuPresenters {
    standard: typeof standard
  }
}
