import { type TablePresenter } from '$ui/table'
import { bva } from '$ui/utils'

export const standard = {
  root: bva('w-full caption-bottom text-sm', { }),
  header: bva('[&_tr]:border-b', { }),
  body: bva('[&_tr:last-child]:border-0', { }),
  row: bva('border-b transition-colors hover:bg-muted/10 data-[state=selected]:bg-muted', { }),
  head: bva('h-10 px-2 text-left align-middle font-medium text-muted [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-0.5', { }),
  cell: bva('p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-0.5', { }),
  caption: bva('mt-4 text-sm text-muted', {}),
  footer: bva('border-t bg-muted/50 font-medium [&>tr]:last:border-b-0', {}),
  empty: bva('p-4 whitespace-nowrap align-middle text-sm text-foreground', {}),
} satisfies TablePresenter

export const bordered = {
  ...standard,
  root: bva('w-full caption-bottom text-sm', { }),
  header: bva('[&_tr]:border-b', { }),
  body: bva('[&_tr:last-child]:border-0', { }),
  row: bva('border-b transition-colors hover:bg-muted/10 data-[state=selected]:bg-muted', { }),
  head: bva(`
    h-10 px-2 text-left align-middle font-medium text-muted border-e last:border-e-0
    [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-0.5
  `, { }),
  cell: bva(`
    p-2 align-middle border-e last:border-e-0
    [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-0.5
  `, { }),
  caption: bva('', {}),
} satisfies TablePresenter


declare module '$ui/table' {
  interface TablePresenters {
    bordered: typeof bordered
  }
}
