import { type AlertPresenter } from '$ui/alert'
import { bva } from '$ui/utils'

export const standard = {
  root: bva(`
    relative w-full rounded-lg border px-4 py-3 text-sm
    [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*]:pl-7
  `, {
    variant: {
      default: 'bg-background text-foreground',
      destructive: 'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive',
    }
  }),
  title: bva('mb-1 font-medium leading-none tracking-tight', { }),
  description: bva('text-sm [&_p]:leading-relaxed', { }),
} satisfies AlertPresenter

declare module '$ui/alert' {
  interface AlertPresenters {
  }
}
