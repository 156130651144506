import createCacheRequest from '$$/lib/createCacheRequest'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRouter, type RouteLocationRaw } from 'vue-router'

export const usePageStore = defineStore('Page', () => {
  const id = ref(0)
  const router = useRouter()
  const obsoleted = ref(false)

  router.afterEach(() => {
    if (obsoleted.value) {
      id.value += 1
      obsoleted.value = false
    }
  })

  function clear() {
    cleanup()
  }

  const { request, cleanup } = createCacheRequest()

  function reload() {
    id.value += 1
  }

  function sweepGoto(to: RouteLocationRaw) {
    router.push(to)
    obsoleted.value = true
  }

  return { id, reload, clear, request, sweepGoto }
})
